import React, { FC } from "react";
import "./messageStyles.css";
import { format } from "date-fns";
import classNames from "classnames";
import { useStyles } from "./styles";
import { MessageProps } from "./types";
import DoneIcon from "@mui/icons-material/Done";
import ReplyIcon from "@mui/icons-material/Reply";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DownloadIcon from "@mui/icons-material/Download";
import { useConsultationContext } from "../../../../contexts/consultationContext";
import {
  ConsultFactory,
  isImageUrl,
  isPdfUrl,
  scrollToRepliedMsg,
} from "../../../../utils/funcs";

type alignType = "right" | "left";

const isAlignedLeft = (align: "right" | "left") => align === "left";

interface MsgContainerType {
  children: React.ReactNode;
  align: alignType;
}

type TimeStampType = Omit<MsgContainerType, "children"> & {
  timeStamp: string;
};

type MsgTimeReadContType = Omit<MsgContainerType, "align">;

const Container: React.FC<MsgContainerType> = ({ children, align }) => {
  return (
    <div
      className={classNames({
        "right-msg-container": !isAlignedLeft(align),
        "left-msg-container": isAlignedLeft(align),
      })}
    >
      {children}
    </div>
  );
};

const MsgContainer: React.FC<MsgContainerType> = ({ children, align }) => {
  return (
    <div
      className={classNames("max-w-[70%] z-10", {
        "right-msg": !isAlignedLeft(align),
        "left-msg": isAlignedLeft(align),
      })}
    >
      {children}
    </div>
  );
};

const TimeStamp: React.FC<TimeStampType> = ({ timeStamp, align }) => {
  return (
    <div
      className={classNames("text-[10px]", {
        "text-black": !isAlignedLeft(align),
        "text-white": isAlignedLeft(align),
      })}
    >
      {timeStamp}
    </div>
  );
};

const MsgTimeReadContainer: React.FC<MsgTimeReadContType> = ({ children }) => {
  return (
    <div className={classNames("flex justify-end items-center pl-10")}>
      {children}
    </div>
  );
};

const DeliveryStatus = ({ status }: { status: "sent" | "delivered" }) => {
  return (
    <div className="flex justify-center items-center ml-2">
      {status === "sent" && (
        <DoneIcon sx={{ fontSize: "0.8rem", color: "#3e5ea9" }} />
      )}
      {status === "delivered" && (
        <DoneAllIcon sx={{ fontSize: "0.8rem", color: "#3e5ea9" }} />
      )}
    </div>
  );
};

const MsgType = (content: string, style: string, isAlignedLeft?: boolean) => ({
  image: (
    <img src={content} className={style} style={{ marginBottom: "5px" }} />
  ),
  pdf: (
    <a
      style={{ textDecoration: "none", marginBottom: "5px" }}
      href={`${content}`}
      rel="noreferrer"
      download=""
    >
      <button
        className={classNames(" hover:underline font-light", {
          "text-white": isAlignedLeft,
          "text-black": !isAlignedLeft,
        })}
      >
        Document <DownloadIcon />
      </button>
    </a>
  ),
  text: (
    <p
      className={classNames("text-sm font-[500] break-words", {
        "text-[#757886]": isAlignedLeft === undefined,
        "text-black": isAlignedLeft === false,
        "text-white": isAlignedLeft === true,
      })}
    >
      {content}
    </p>
  ),
});

const MsgContent = ({
  content,
  isAlignedLeft,
}: {
  content: string;
  isAlignedLeft?: boolean;
}) => {
  const classes = useStyles();
  const type = () => {
    if (isImageUrl(content)) {
      return "image";
    } else if (isPdfUrl(content)) {
      return "pdf";
    } else {
      return "text";
    }
  };
  return MsgType(content, classes.imgUpload, isAlignedLeft)[type()];
};

export const Message: FC<MessageProps> = (props) => {
  const { align, message, status, showStatus, onClickReply } = props || {};
  const { timestamp, _id, content } = message;
  const isAlignedLeft = align === "left";
  const stamp = `${format(timestamp?.toDate(), "p")}`;
  const repliedTo = props?.message?.replyTo;
  const consultationDetails = useConsultationContext();
  const { doctor, patient } = React.useMemo(
    () => ConsultFactory(consultationDetails || undefined),
    [consultationDetails]
  );

  return (
    <div
      id={`msg_${_id}`}
      className={classNames(`relative transition-all`, {
        "msg-left": isAlignedLeft,
        "msg-right": !isAlignedLeft,
      })}
    >
      <Container align={align}>
        {!isAlignedLeft && (
          <button
            onClick={(e) => {
              e.preventDefault();
              onClickReply();
            }}
            className="btn-right my-auto"
          >
            <ReplyIcon />
          </button>
        )}
        <MsgContainer align={align}>
          {repliedTo && (
            <div
              onClick={(e) => {
                e.preventDefault();
                scrollToRepliedMsg(
                  repliedTo?._id,
                  repliedTo?.idFrom !== patient._id
                );
              }}
              className={classNames(
                "border-l-[3px] pl-4 py-2 pr-2 my-2 rounded-md mb-4",
                {
                  " border-primary bg-gray-200": !isAlignedLeft,
                  " border-blue-300 bg-gray-100": isAlignedLeft,
                }
              )}
            >
              <p className={classNames("text-primary text-xs font-medium")}>
                {repliedTo?.idFrom === patient._id
                  ? "You"
                  : `Dr. ${doctor.firstName}`}
              </p>
              <p className="text-sm font-light text-black break-words">
                {MsgContent({ content: repliedTo?.content })}
              </p>
            </div>
          )}
          <div>
            {MsgContent({ content, isAlignedLeft })}
            <MsgTimeReadContainer>
              <TimeStamp timeStamp={stamp} align={align} />
              {showStatus && <DeliveryStatus status={status} />}
            </MsgTimeReadContainer>
          </div>
        </MsgContainer>
        {isAlignedLeft && (
          <button onClick={onClickReply} className="flip btn-left my-auto">
            <ReplyIcon />
          </button>
        )}
      </Container>
    </div>
  );
};
